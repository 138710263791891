import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useClassesList() {
  // Use toast
  const toast = useToast()

  const fetchStaffList = queryParam => axios
    .get('/auth/staffs', {
      params: queryParam,
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching staff list',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchGroupType = queryParams => axios
    .get(`auth/group-types/${queryParams.id}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching group type',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchSessions = queryParams => axios
    .get('auth/sessions', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching sessions',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchParentGroup = queryParams => axios
    .get(`auth/groups-by-type/${queryParams.id}`, {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching parrent group',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchStaffList,
    fetchGroupType,
    fetchSessions,
    fetchParentGroup,
  }
}
