<template>
  <b-modal
    id="edit-bulk-groups"
    ref="edit-bulk-groups"
    centered
    :title="'Confirm the edits (' + markedGroups.length + ' groups selected)'"
    ok-title="Confirm"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    size="lg"
    @ok="saveBulkChanges"
  >
    <form
      ref="form"
    >
      <b-row>
        <b-col
          sm="6"
        >
          <b-form-group
            label="Group Name"
          >
            <b-form-input
              v-model="groupData.groupName"
              placeholder="No Change"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="6"
        >
          <b-form-group
            label="Group Type"
          >
            <v-select
              v-model="groupData.groupType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="groupTypeOptions"
              :placeholder="'No Change'"
              :clearable="false"
              value="value"
              label="text"
              :reduce="type => type.value"
              @input="handleGroupTypeSelected"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="6"
        >
          <b-form-group
            label="Status"
          >
            <v-select
              v-model="groupData.groupStatus"
              :options="groupStatusOptions"
              :clearable="false"
              placeholder="No Change"
              :reduce="status => status.value"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="6"
        >
          <b-form-group
            label="Parent Group"
          >
            <v-select
              v-model="groupData.parentGroup"
              :disabled="groupParentOptions.length === 0"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="groupParentOptions"
              label="text"
              :clearable="false"
              :placeholder="fieldStatus"
              @input="fetchSessionGroup"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            :label="sessionSemester(2)"
          >
            <v-select
              v-model="groupData.sessions"
              :disabled="groupSessionsOptions.length === 0"
              :options="groupSessionsOptions"
              label="text"
              multiple
              :clearable="false"
              :placeholder="fieldSessionStatus"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="groupData.sessions.length"
          sm="12"
          class="mt-2"
        >
          <!-- checkbox -->
          <b-form-group>
            <b-form-checkbox
              id="remember-me"
              v-model="isCustomPricingAvailable"
              name="checkbox-1"
            >
              Add custom pricing
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col
          v-if="groupData.sessions.length && isCustomPricingAvailable"
          sm="12"
          class="mt-2"
        >

          <template v-for="(session, index) in groupData.sessions">
            <b-row
              :key="index"
              class="d-flex align-items-center mb-2"
            >
              <b-col
                sm="6"
              >
                <p class="mt-1 mb-0">
                  {{ session.text }} addon
                </p>
              </b-col>

              <b-col sm="6">
                <label
                  class="d-flex d-inline align-items-center"
                >
                  <strong class="mr-1 font-weight-bold">($)</strong>
                  <b-form-input
                    v-model="session.price"
                    type="number"
                    class="sm-4"
                    placeholder="0"
                  />
                </label>

              </b-col>
            </b-row>
          </template>
        </b-col>
      </b-row>

      <!-- Custom Fields -->
      <hr>
      <b-row v-if="groupData.customFields">
        <b-col
          v-for="(attribute, index) of customAttributes"
          :key="index"
          sm="6"
        >
          <b-form-group
            :label="title(attribute, '_')"
          >
            <b-form-select
              v-if="attribute.toLowerCase().trim() === 'language'"
              v-model="groupData.customFields[attribute]"
              :options="languageOptions"
            />
            <b-form-input
              v-else
              v-model="groupData.customFields[attribute]"
              placeholder="No change"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Admin Rules -->
      <hr>
      <b-row>
        <b-col
          sm="6"
        >
          <b-form-group
            label="Visible"
          >
            <b-form-select
              v-model="groupData.settings.is_visible"
              :options="boolOptions"
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="6"
        >
          <b-form-group
            label="Allow User Selection"
          >
            <b-form-select
              v-model="groupData.settings.is_allow_use_selection"
              :options="boolOptions"
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="6"
        >
          <b-form-group
            label="Restrict to Single Group of X Type"
          >
            <b-form-select
              v-model="groupData.settings.is_restrict_single_group_type"
              :options="boolOptions"
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="6"
        >
          <b-form-group
            label="Capacity Enforcement"
          >
            <b-form-select
              v-model="groupData.settings.is_capacity_enforcement"
              :options="boolOptions"
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="6"
        >
          <b-form-group
            label="Allow viewing other group members"
          >
            <b-form-select
              v-model="groupData.settings.is_allow_viewing_other_members"
              :options="boolOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </form>
  </b-modal>

</template>

<script>
import {
  BRow, BCol, BFormInput, BFormCheckbox, VBModal, BFormSelect, BFormGroup,
} from 'bootstrap-vue'
import { title } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import useGroupList from '@/views/admin/group/group-list/useGroupList'

export default {
  name: 'GroupBulkEditModal',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormSelect,

    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    markedGroups: {
      type: Array,
      required: true,
    },
    groupTypeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      groupData: {},
      customAttributes: [],
      isCustomPricingAvailable: false,
      groupParentOptions: [],
      groupSessionsOptions: [],
      fieldStatus: 'No change',
      fieldSessionStatus: 'No change',
    }
  },
  computed: {
    boolOptions() {
      return [
        { value: null, text: 'No change' },
        { value: true, text: 'Active' },
        { value: false, text: 'Inactive' },
      ]
    },
    languageOptions() {
      return [
        { value: null, text: 'No change' },
        { value: 'English', text: 'English' },
      ]
    },
  },
  watch: {
    markedGroups() {
      this.fetchGroupsCustomAttributes()
    },
  },
  setup() {
    // Use toast
    const toast = useToast()

    const {
      groupStatusOptions,
    } = useGroupList()

    return {
      title,
      toast,
      groupStatusOptions,
    }
  },
  async created() {
    this.initGroupStaticFields()
  },
  methods: {
    initGroupStaticFields() {
      this.groupData = {
        groupName: null,
        groupType: null,
        groupStatus: null,
        parentGroup: null,
        sessions: [],
        customFields: {},
        settings: {
          is_visible: false,
          is_allow_use_selection: false,
          is_restrict_single_group_type: false,
          is_capacity_enforcement: false,
          is_allow_viewing_other_members: false,
          is_bulk_create: false,
        },
      }
    },
    async fetchGroupsCustomAttributes() {
      this.customAttributes = await this.$store.dispatch('app-group/fetchGroupsCustomFieldsAttributes', {
        groupId: this.markedGroups,
      })
        .then(response => response.data.data.reduce((arr, item) => {
          arr.push(item)
          return arr
        }, []))
    },
    async fetchParentGroup() {
      this.fieldStatus = 'Loading...'
      this.groupParentOptions = await this.$store.dispatch('app-group/fetchParentGroup', {
        id: this.groupData.groupType,
      })
        .then(response => response.data.data.reduce((arr, item) => {
          arr.push({ value: item, text: item.name })
          return arr
        }, [{ value: null, text: 'No Change' }]))
        .finally(() => {
          this.fieldStatus = 'No change'
        })
    },
    async fetchSessionGroup() {
      this.groupData.sessions = []
      this.fieldSessionStatus = 'Loading...'
      this.groupSessionsOptions = await this.$store.dispatch('app-group/fetchSessions', {
        programId: this.groupData.parentGroup.value.program_id,
      })
        .then(response => response.data.data.reduce((arr, item) => {
          arr.push({ value: item.id, text: item.name })
          return arr
        }, [{ value: null, text: 'No Change' }]))
        .finally(() => {
          this.fieldSessionStatus = 'No change'
        })
    },
    async saveBulkChanges(bvModalEvt) {
      bvModalEvt.preventDefault()

      const formData = {
        groups: this.markedGroups,
        name: this.groupData.groupName,
        type_id: this.groupData.groupType,
        group_id: this.groupData.parentGroup?.value.id,
        status: this.groupData.groupStatus,
        group_settings: this.groupData.settings,
        custom_fields: this.groupData.customFields,
      }

      if (this.isCustomPricingAvailable) {
        formData.sessions = this.groupData.sessions.map(session => ({
          id: session.value,
          price: session.price ? session.price : 0,
        }))
      }

      this.$store.dispatch('app-group/saveBulkGroups', formData)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Bulk Groups were successfully updated',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          this.$nextTick(() => {
            this.$refs['edit-bulk-groups'].toggle('#toggle-btn')
            this.clearForm()
          })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating Groups',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async clearForm() {
      this.initGroupStaticFields()
      this.groupParentOptions = []
      this.groupSessionsOptions = []
      this.$emit('reload')
    },
    async handleGroupTypeSelected() {
      await this.fetchParentGroup()

      // if (this.groupData.groupType === this.divisionTypeId) {
      //   this.requiredDivisionCustomFields.forEach(attribute => {
      //     const index = this.customAttributes.indexOf(attribute)
      //     if (index < 0) {
      //       this.customAttributes.push(attribute)
      //     }
      //   })
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
