<template>
  <b-overlay
    id="overlay-background"
    :show="isLoading"
    variant="white"
    opacity="0.7"
    rounded="sm"
  >
    <div>
      <!-- Per Page -->
      <b-col
        cols="12"
        class="d-flex align-items-center justify-content-between my-1"
      >
        <div>
          <label class="mr-1 mb-0">Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            :reduce="option => option.value"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </div>
        <div
          v-b-modal.select-ppa-columns
          class="d-flex align-items-center cursor-pointer"
        >
          <feather-icon
            icon="SettingsIcon"
            size="18"
            class="text-primary mr-1"
          />
          PPA Columns
        </div>
      </b-col>

      <b-table
        ref="groupUsersList"
        :fields="tableColumns"
        :items="tableItems"
        primary-key="id"
        show-empty
        details-td-class="p-0"
        empty-text="No users in the group"
        class="position-relative"
      >
        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <template #row-details>
          <div />
        </template>

        <template #head()="data">
          <span>
            {{ data.label | fixTableHeaderView }}
          </span>
        </template>

        <template
          v-for="(slot, index) in tableColumns"
          v-slot:[`cell(${slot})`]="data"
        >
          <span :key="index">
            <img
              v-if="checkIfSlotValueIsBase64File(data.item[slot])"
              class="image-slot"
              :src="data.item[slot]"
            >
            <span v-else> {{ data.item[slot] }} </span>
          </span>
        </template>

        <!-- Column: name -->
        <template #cell(full_name)="data">
          <b-link
            :to="getUserViewRoutByType(data.item)"
          >{{ data.item.full_name }}</b-link>
        </template>

        <!-- Column: tags -->
        <template #cell(tags)="data">
          <div class="d-flex justify-content-between">
            <badge-tag-list
              v-if="!$can('assign', permissionSubjects.Tags)"
              :tag-names="data.item.tags"
            />
            <badge-tag-list
              v-else-if="!data.detailsShowing"
              :tag-names="data.item.tags"
              class="cursor-pointer"
              @click.native="data.toggleDetails"
            />
            <tags-edit-selector
              v-else
              :application-id="data.item.application_id"
              :tags="data.item.tags"
              :tag-set-list="tagSetOptionsList"
              @tagsUpdated="updateTableItemTags(data.item.application_id, $event)"
              @closeEditing="data.toggleDetails"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </div>

    <select-ppa-columns
      :ppa-list="ppaList"
      @updateData="fetchGroupUsersList"
    />
  </b-overlay>
</template>

<script>
import axios from '@axios'
import {
  BTable, BLink, BRow, BCol, BPagination, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'

import BadgeTagList from '@/views/components/badge-tag-list/BadgeTagList.vue'
import SelectPpaColumns from './modals/SelectPpaColumns.vue'
import TagsEditSelector from '@/views/components/tags-edit-selector/TagsEditSelector.vue'
import { PER_PAGE_OPTIONS } from '@/constants/base'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'GroupUsers',
  components: {
    BTable,
    BLink,
    BRow,
    BCol,
    BPagination,
    BOverlay,

    vSelect,
    TableSpinner,

    BadgeTagList,
    SelectPpaColumns,
    TagsEditSelector,
  },
  filters: {
    fixTableHeaderView(val) {
      val.split()
      return val.split('.').join(': ').replace(/_/g, ' ')
    },
  },
  props: {
    groupId: {
      type: String,
      required: true,
    },
    programId: {
      type: String,
      required: true,
    },
    isAllExpanded: {
      type: Boolean,
      required: true,
    },
    groupUsers: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      perPage: 25,
      totalItems: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      perPageOptions: PER_PAGE_OPTIONS,
      currentPage: 1,
      tableColumns: [],
      tableItems: [],
      permissionSubjects,
    }
  },
  computed: {
    ppaList() {
      return this.$store.getters['app-group/getPpaList']
    },
    tagSetOptionsList() {
      return this.$store.getters['tags/getTagSetOptionsList']
    },
    localStorageItemName() {
      return `groupPpaColumns-${this.programId}`
    },
  },
  watch: {
    perPage() {
      this.fetchGroupUsersList()
    },
    currentPage() {
      this.fetchGroupUsersList()
    },
  },
  mounted() {
    this.setTableData()
  },
  methods: {
    getUserViewRoutByType(item) {
      if (item.is_student) {
        return { name: 'admin-application-edit', params: { id: item.application_id, type: this.camperStudent(1) } }
      }
      return { name: 'admin-application-edit', params: { id: item.application_id, type: 'Staff' } }
    },
    setTableData() {
      if (this.isAllExpanded && this.groupUsers) {
        this.tableItems = this.groupUsers.data
        const { total, from, to } = this.groupUsers.meta
        this.totalItems = total
        this.dataMeta = {
          from,
          to,
          of: total,
        }
        this.setTableColumns(this.groupUsers.data)
      } else {
        this.fetchGroupUsersList()
      }
    },
    async fetchGroupUsersList() {
      try {
        this.isLoading = true

        const queryParams = {
          group_id: this.groupId,
          program_id: this.programId,
          perPage: this.perPage,
          page: this.currentPage,
          ppa: JSON.parse(localStorage.getItem(this.localStorageItemName)),
        }

        const response = await axios
          .get('/auth/groups/extra/details', {
            params: queryParams,
          })
        const { total, from, to } = response.data.meta
        this.totalItems = total
        this.dataMeta = {
          from,
          to,
          of: total,
        }
        this.tableItems = response.data.data
        this.setTableColumns(response.data.data)

        return response.data.data
      } catch (error) {
        return []
      } finally {
        this.isLoading = false
      }
    },
    setTableColumns(data) {
      if (data[0]) {
        this.tableColumns = Object.keys(data[0])
          .filter(item => !['application_id', 'is_student'].includes(item))
      } else {
        this.tableColumns = []
      }
    },
    updateTableItemTags(applicationId, tags) {
      const item = this.tableItems.find(item => item.application_id === applicationId)
      if (item) item.tags = tags
      this.$forceUpdate()
    },
    checkIfSlotValueIsBase64File(item) {
      if (item) {
        const matches = item.toString().match(/^data:image\/(\w+);base64,/)
        if (!matches) {
          return false
        }
        return matches.length > 0
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  .image-slot {
    max-height: 5vh;
  }
</style>
