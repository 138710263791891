<template>
  <!-- Create Group -->
  <b-modal
    id="add-group"
    ref="add-group"
    centered
    title="Create Group"
    ok-title="Create Group"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @ok="postGroup"
    @show="clearGroupCreatingForm"
  >
    <validation-observer
      ref="groupRules"
      tag="form"
    >
      <form
        ref="form"
      >
        <b-row>

          <!--  Group Name  -->
          <b-col
            sm="6"
            class="mb-1"
          >
            <b-form-group
              label="Group Name"
            >
              <validation-provider
                #default="{ errors }"
                name="group name"
                rules="required"
              >
                <b-form-input
                  v-model="createGroup.groupName"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!--  Group Type  -->
          <b-col
            sm="6"
            class="mb-1"
          >
            <b-form-group
              label="Group Type"
            >
              <validation-provider
                #default="{ errors }"
                name="group type"
                rules="required"
              >
                <v-select
                  v-model="createGroup.groupType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="groupTypeOptions"
                  :placeholder="'Select Group Type'"
                  :clearable="false"
                  value="value"
                  label="text"
                  :reduce="type => type.value"
                  :class="{'is-invalid': errors.length > 0 }"
                  @input="selectedGroupType"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!--  Status  -->
          <b-col
            sm="6"
            class="mb-1"
          >
            <b-form-group
              label="Status"
            >
              <validation-provider
                #default="{ errors }"
                name="status"
                rules="required"
              >
                <v-select
                  v-model="createGroup.groupStatus"
                  :options="groupStatusOptions"
                  :clearable="false"
                  placeholder="Select Status"
                  :reduce="status => status.value"
                  :class="{'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!--  Parent Group  -->
          <b-col
            sm="6"
            class="mb-1"
          >
            <b-form-group
              :label="createGroup.isSubgroup ? 'Parent Group' : 'Choose Program'"
            >
              <validation-provider
                #default="{ errors }"
                name="parent group"
                rules="required"
              >
                <v-select
                  v-model="createGroup.parentGroup"
                  :disabled="groupParentOptions.length === 0"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="groupParentOptions"
                  label="text"
                  :clearable="false"
                  :placeholder="fieldStatus"
                  :class="{'is-invalid': errors.length > 0 }"
                  @input="selectParentGroup"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!--  Sessions  -->
          <b-col sm="12">
            <b-form-group
              :label="sessionSemester(2)"
            >
              <validation-provider
                #default="{ errors }"
                name="sessions"
                rules="required"
              >
                <v-select
                  v-model="createGroup.sessions"
                  :disabled="groupSessionsOptions.length === 0"
                  :options="groupSessionsOptions"
                  label="text"
                  multiple
                  :clearable="false"
                  :placeholder="fieldSessionStatus"
                  :class="{'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="createGroup.sessions.length"
            sm="12"
            class="mt-2"
          >
            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="isCustomPricingAvailable"
                name="checkbox-1"
              >
                Add custom pricing
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!--  Sessions Custom Pricing  -->
          <b-col
            v-if="createGroup.sessions.length && isCustomPricingAvailable"
            sm="12"
            class="mt-2"
          >

            <template v-for="(session, index) in createGroup.sessions">
              <b-row
                :key="index"
                class="d-flex align-items-center mb-2"
              >
                <b-col
                  sm="6"
                  class="mb-1"
                >
                  <p class="mt-1 mb-0">
                    {{ session.text }} addon
                  </p>
                </b-col>

                <b-col sm="6">
                  <label
                    class="d-flex d-inline align-items-center"
                  >
                    <strong class="mr-1 font-weight-bold">($)</strong>
                    <b-form-input
                      v-model="session.price"
                      type="number"
                      class="sm-4"
                      placeholder="0"
                    />
                  </label>

                </b-col>
              </b-row>
            </template>
          </b-col>

          <template>
            <b-col
              v-if="groupTypeFieldsList.length"
              sm="12"
              class="my-2"
            >
              <hr>
              <span>Group Type Custom Fields</span>
            </b-col>

            <group-custom-field-row-from-type
              v-for="(item, index) in groupTypeFieldsList"
              :key="item.frontID"
              :fields-data="item"
              :field-index="index"
            />

            <b-col
              v-if="groupTypeFieldsList.length"
              sm="12"
            >
              <hr>
            </b-col>
          </template>

          <b-col
            v-if="groupCustomFieldsList.length"
            sm="12"
            class="my-2"
          >
            <span>Group Custom Fields</span>
          </b-col>

          <group-custom-fields
            :fields-list="groupCustomFieldsList"
          />

          <b-col
            sm="12"
          >
            <hr>
          </b-col>

          <b-col
            sm="12"
            class="mt-2"
          >
            <span>Auto-Assignments</span>
            <group-type-auto-assigns
              :assigns-list="assignsList"
              :custom-fields-list="groupCustomFieldsList"
            />
          </b-col>

          <!-- Settings Checkboxes  -->
          <group-admin-rules-component
            :group-data="createGroup.group_settings"
            :type="'Group'"
            :action="'CreateGroup'"
            :custom-fields-list="groupCustomFieldsList"
          />

        </b-row>
      </form>
    </validation-observer>
  </b-modal>

</template>

<script>
import {
  BRow, BCol, BFormInput, BFormCheckbox, VBModal, BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import axios from '@axios'
import { onUnmounted } from '@vue/composition-api'
import { formBuilderPreparations } from '@core/mixins/formBuilderPreparations'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import storeModule from '@/views/admin/group/groupStoreModule'
import useGroupList from '@/views/admin/group/group-list/useGroupList'
import useGroupCreateModal from '@/views/admin/group/group-forms/useGroupCreateModal'
import GroupAdminRulesComponent from '@/views/admin/group/group-forms/components/GroupAdminRulesComponent.vue'
import GroupCustomFieldRowFromType from '@/views/admin/group/group-forms/components/GroupCustomFieldRowFromType.vue'
import GroupCustomFields from '@/views/admin/group/group-forms/components/GroupCustomFields.vue'
import GroupTypeAutoAssigns from '@/views/admin/group/group-forms/components/GroupTypeAutoAssigns.vue'

export default {
  name: 'GroupCreateModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,

    vSelect,
    GroupAdminRulesComponent,
    GroupCustomFieldRowFromType,
    GroupCustomFields,
    GroupTypeAutoAssigns,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [formBuilderPreparations],
  props: {
    groupTypeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      createGroup: {},
      isCustomPricingAvailable: false,
      groupTypeFieldsList: [],
      groupCustomFieldsList: [],
      assignsList: [],
      groupParentOptions: [],
      groupSessionsOptions: [],
      groupTypeStatus: 'Loading...',
      fieldStatus: 'Loading...',
      fieldSessionStatus: 'Loading...',
      createdGroupId: '',
      required,
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-create-group'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      programId,
      groupStatusOptions,
    } = useGroupList()

    const {
      fetchStaffList,
      fetchGroupType,
      fetchSessions,
      fetchParentGroup,
    } = useGroupCreateModal()

    return {
      programId,
      groupStatusOptions,
      fetchStaffList,
      fetchGroupType,
      fetchSessions,
      fetchParentGroup,
    }
  },
  async created() {
    this.initCreateGroupFormData()
  },
  methods: {
    initCreateGroupFormData() {
      this.createGroup = {
        groupName: null,
        groupType: null,
        groupStatus: null,
        parentGroup: null,
        sessions: [],
        group_settings: {
          is_visible: false,
          is_allow_use_selection: false,
          is_restrict_single_group_type: false,
          is_capacity_enforcement: false,
          is_allow_viewing_other_members: false,
          is_bulk_create: false,
          capacity: 0,
        },
        isSubgroup: false,
      }

      this.groupCustomFieldsList = []
      this.assignsList = []
      this.createdGroupId = ''
    },
    async selectedGroupType() {
      this.createGroup.parentGroup = null
      this.createGroup.sessions = []
      this.groupSessionsOptions = []
      await this.fetchGroupTypeDetails()
      await this.hendleFetchParentGroup()
    },
    async fetchGroupTypeDetails() {
      this.fieldStatus = 'Loading...'
      await this.fetchGroupType({
        id: this.createGroup.groupType,
      })
        .then(response => {
          this.createGroup.group_settings = response.settings
          if (this.createGroup.group_settings) {
            delete this.createGroup.group_settings.id
          }

          this.createGroup.isSubgroup = response.parent?.name !== 'Program'
          this.groupTypeCustomFields = response.fields.map(row => ({
            id: null,
            field_id: row.id,
            value: row.type === 'checkbox' ? [row.default] : row.default,
            label: row.label,
            name: row.name,
            options: this.designateOptions(row.type, row.options),
            type: this.transformField(row.type),
            required: row.required,
            readonly: true,
            disabled: false,
          }))
          this.groupTypeFieldsList = JSON.parse(JSON.stringify(this.groupTypeCustomFields))
        })
    },
    async hendleFetchParentGroup() {
      this.fieldStatus = 'Loading...'
      await this.fetchParentGroup({
        id: this.createGroup.groupType,
        program_id: this.programId,
      })
        .then(response => {
          this.groupParentOptions = response.map(item => ({ value: item, text: item.name }))
          this.fieldStatus = this.createGroup.isSubgroup ? 'Select Group' : 'Select Program'
        })
    },
    async selectParentGroup() {
      await this.fetchSessionGroup()
      await this.prepareStaffList()
    },
    async fetchSessionGroup() {
      this.createGroup.sessions = []
      this.fieldSessionStatus = 'Loading...'
      await this.fetchSessions({
        programId: this.createGroup.parentGroup.value.program_id,
      })
        .then(response => {
          this.groupSessionsOptions = response.map(item => ({ value: item.id, text: item.name }))
          this.fieldSessionStatus = `Select ${this.sessionSemester(1)}`
        })
    },
    async prepareStaffList() {
      const staffField = this.groupTypeFieldsList.find(item => item.type === 'staff')
      if (staffField) {
        staffField.options = []
        await this.fetchStaffList({
          programId: this.createGroup.parentGroup.value.program_id,
        })
          .then(response => {
            staffField.options = response.map(item => ({ id: item.user.id, label: item.user.full_name }))
          })
      }
    },
    prepareCustomFieldsFromTypeFields() {
      const groupTypeFieldsList = this.groupTypeFieldsList.map(row => ({
        label: row.label,
        attribute: row.name,
        value: row.value,
        group_type_custom_field_id: row.field_id,
      }))

      const groupCustomFieldsList = this.groupCustomFieldsList.map(row => ({
        id: row.id,
        label: row.label,
        attribute: row.attribute,
        value: row.value,
      }))

      return [...groupTypeFieldsList, ...groupCustomFieldsList]
    },
    async postGroup(bvModalEvt) {
      bvModalEvt.preventDefault()

      const formData = {
        name: this.createGroup.groupName,
        type_id: this.createGroup.groupType,
        group_id: this.createGroup.parentGroup?.value.id,
        status: this.createGroup.groupStatus,
        group_settings: this.createGroup.group_settings,
        sessions: this.createGroup.sessions.map(session => ({
          id: session.value,
          price: session.price ? session.price : 0,
        })),
        custom_fields: this.prepareCustomFieldsFromTypeFields(),
        auto_assigns: this.assignsList,
      }

      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs.groupRules.validate().then(success => {
          if (success) {
            axios
              .post('/auth/groups', formData)
              .then(response => {
                this.createdGroupId = response.data.data.id
                this.$nextTick(() => {
                  this.$refs['add-group'].toggle('#toggle-btn')
                  if (this.createdGroupId) {
                    this.$emit('new-group-created', this.createdGroupId)
                  } else {
                    this.$emit('reload', this.createdGroupId)
                  }
                })
                return resolve(response)
              })
              .then(() => {
                this.$emit('reload')
              })
              .catch(error => reject(error))
          } else {
            reject()
          }
        })
      })
    },
    async clearGroupCreatingForm() {
      this.initCreateGroupFormData()
      this.groupParentOptions = []
      this.groupSessionsOptions = []
      this.groupTypeFieldsList = []
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-autosuggest.scss';

  .is-invalid {
    .vs__dropdown-toggle {
      border: 1px solid #ea5455;
    }
  }
</style>
