<template>
  <!-- Group Bulk Assign-->
  <b-modal
    id="bulk-create"
    ref="bulk-create"
    centered
    title="Bulk Create"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
  >
    <form
      ref="form"
      class="px-4"
    >
      <b-row>

        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Select CSV File"
          >
            <b-form-file
              ref="refInputEl"
              v-model="importFile"
              accept=".csv"
              placeholder="Choose file"
              @change="handleFileChange"
            />
          </b-form-group>

          <b-link
            @click="exportExampleCSV"
          >
            <u>Download Template CSV</u>
          </b-link>
        </b-col>

      </b-row>
    </form>

    <div
      v-if="validationErrors.length"
      class="form-group pt-1"
    >
      <p
        v-for="(validationError, index) in validationErrors"
        :key="index"
        class="text-center text-danger"
      >
        {{ validationError }}
      </p>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="primary"
          class="float-right"
          :disabled="!importFile || isLoading"
          @click="uploadFile"
        >
          <b-spinner
            v-if="isLoading"
            label="Spinning"
            small
          />
          <span v-else>Bulk Create</span>
        </b-button>
      </div>
    </template>
  </b-modal>

</template>

<script>
import {
  BRow, BCol, VBModal, BFormGroup, BFormFile, BButton, BLink, BSpinner,
} from 'bootstrap-vue'

import { useToast } from 'vue-toastification/composition'
import { title } from '@core/utils/filter'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'GroupBulkCreateModal',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BButton,
    BLink,
    BSpinner,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [downloadExportFile],
  data() {
    return {
      importFile: null,
      validationErrors: [],
      isLoading: false,
    }
  },
  setup() {
    // Use toast
    const toast = useToast()

    return {
      title,
      toast,
    }
  },
  methods: {
    handleFileChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.importFile = e.target.files[0]
    },
    uploadFile() {
      const formData = new FormData()
      formData.append('file', this.importFile)

      this.validationErrors = []

      this.isLoading = true

      this.$store.dispatch('app-group/importBulkCreateGroups', formData)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Bulk Create Groups operation was successful',
              text: `${response.created_groups} of new groups were successfully created`,
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          this.$nextTick(() => {
            this.$refs['bulk-create'].toggle('#toggle-btn')
            this.clearForm()
          })
        })
        .catch(error => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating Groups',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          if (error.response.status === 422) {
            const validationErrors = Object.values(error.response.data.errors)
            this.validationErrors = validationErrors.flat()
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    clearForm() {
      this.importFile = null
      this.$emit('reload')
    },
    async exportExampleCSV() {
      this.downloadFile(['group_name', 'group_type', 'group_parent_id', 'program_id', 'session_ids'], 'bulk_create_template', 'csv')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
